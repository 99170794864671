.App-header {
  position: sticky;
  z-index: 7;
  top: 0;
  background-color: #222;
  height: 7vh;
  padding: 10px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-logo {
  object-fit: contain;
  width: 2em;
  cursor: pointer;
}

.App-title {
  margin-left: 8px;
  margin-bottom: 0;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  white-space: nowrap;
}

.header-icons {
  display: flex;
  align-items: center;
  display: none;
  color: #66acff;
  justify-content: space-between;
}

.download-icons {
  display: flex;
  align-items: center;
  font-size: 28px;
}

.download-icon {
  margin-left: 10px;
}

.header-icon:hover {
  cursor: pointer;
}

.nav-header {
  display: flex;
  align-items: center;
}

.download_icon {
  object-fit: contain;
  width: 2.5em;
  color: white;
}

.ant-dropdown-link {
  font-weight: 500;
  font-size: 1.25em;
  color: white;
}

.ant-dropdown-link:hover {
  color: #66acff;
}

.postman-run-button {
  margin-top: 12px;
  margin-left: 12px;
}

.version-dropdown {
  margin-right: 1.5rem;
  white-space: nowrap;
}

.version-tag {
  font-size: 16px;
  color: white;
}

@media screen and (max-width: 950px) {
  .version-dropdown,
  .header-icons {
    scale: 1;
  }

  .version-dropdown {
    margin: 0.3rem 0;
  }

  .App-title {
    display: none;
  }

  .download-icons {
    margin-left: 0;
  }

  .App-logo {
    margin-right: 4px;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-tag {
    display: none;
  }
}
