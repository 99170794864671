@import "~antd/dist/antd.css";

.fDpVFP {
  display: none;
}

.App {
  height: 100vh;
}

a[href="https://redocly.com/redoc/"]
{
  opacity: 0;
}
