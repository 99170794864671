.sections {
  padding-right: 20px;
  overflow-y: scroll;
}

.section {
  padding: 0.5em;
  margin-top: 0.15em;
}

.heading {
  font-weight: 400;
  font-size: 2em;
  line-height: 1.6em;
  color: #32329f;
  font-family: Montserrat, sans-serif;
}

.info {
  letter-spacing: 0.4px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table tr:nth-child(odd)>td {
  background-color: white;
}

table tr:nth-child(even)>td {
  background-color: #e0fafa;
}

table td {
  padding: 10px;
}

.highlight {
  background-color: gray;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.marked {
  background-color: rgb(32, 29, 58);
  padding: 10px;
  color: white;
  border-radius: 4px;
}