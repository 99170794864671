:root {
  --bg: rgb(32, 29, 58);
}

.menu-bar {
  position: sticky;
  font-family: Montserrat, sans-serif;
  background-color: var(--bg);
  flex-shrink: 0;
  width: 23vw;
}

.menu-title {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.menu-header {
  padding: 20px 10px 10px 10px;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  background-color: var(--bg);
  line-height: 1.25em;
}

.menu-link {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: #fff;
  background-color: var(--bg);
  height: 25px !important;
  font-size: 14px;
  line-height: 20px !important;
  font-family: muli, sans-serif;
  display: flex;
  align-items: center;
  border-left: 3px solid var(--bg);
}

.menu-link a {
  color: #fff;
}

.menu-link:hover a {
  color: #fff;
}

.menu-link:hover {
  border-left: 3px solid rgb(111, 111, 236);
  background-color: rgb(50, 26, 104);
}

.dashboard-link {
  color: #ffbe20;
  cursor: pointer;
}

.dashboard-link:hover {
  text-decoration: underline;
}

.ant-menu-item-selected {
  border-left: 3px solid rgb(111, 111, 236);
  background-color: rgb(50, 26, 104) !important;
}

.menu-header-small {
  padding-top: 10px;
  padding-left: 18px;
  color: #fff;
  font-size: 0.8em;
  font-weight: 600;
  background-color: var(--bg);
  line-height: 1.5em;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

@media screen and (max-width: 950px) {
  .menu-bar {
    width: initial;
  }
}
